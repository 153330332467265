.edit-user-header {
    font-size: 20px;
    font-weight: 500;
    color: black;
}

.overlay-dr {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}


.dialog-containe-edit-user {
    max-width: 80%;
    /* Adjust the maximum width as needed */
}

.save-confirm-dialog-container {
    width: 400px !important;
    height: auto;
    position: absolute !important;
    top: 330px !important;
    left: 37% !important;
    display: flex !important;
    flex-direction: column !important;
    /* background-color: #3498db; */
    z-index: 100 !important;
}

.dialog-containe-edit-user .save-confirm-dialog-container {
    width: 400px !important;
    height: auto;
    position: absolute !important;
    top: 330px !important;
    left: 37% !important;
    display: flex !important;
    flex-direction: column !important;
    /* background-color: #3498db; */
    z-index: 100 !important;
}

.edit-user-sub-header {
    font-size: 17px;
    font-weight: 500;
    color: black;
    margin-bottom: 0;
}

.cls-input-field {

    font-size: 14px;
    font-weight: 400;

}

.edit-user-container {
    overflow-y: scroll;
    max-height: 850px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    font-weight: 400;
    font-size: 14px;
}

.edit-user-col {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

#edit-user-table tr {
    padding-top: 0;
}

#edit-user-table {
    display: flex;
    flex-direction: column;
}

.drop-ops-area {
    display: flex;
    flex-direction: column;
}

#edit-user-table td {
    padding-top: 8px;
    min-width: 350px;

}

.avail-substn {
    border: #828282 solid 1px;
    border-radius: 5px;
    /* width: 75px;
    height: 20px !important; */
    float: left;
    color: black;
    background-color: #EBEBEB;
    padding: 4px;
    margin: 4px;
    font-size: 12px;
}

.edid-drop-down {
    text-align: left;
}

.ABB_CommonUX_Dropdown__label {
    text-align: left !important;
}

@media (max-width: 1920px) {


    .edit-user-container {
        overflow-y: scroll;
        max-height: 90vh;
        /* min-height: 90vh; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        font-weight: 400;
        font-size: 14px;
    }
}

@media (max-width: 1440px) {

    .edit-user-container {
        overflow-y: scroll;
        max-height: 90vh;
        /* min-height: 90vh; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        font-weight: 400;
        font-size: 14px;
    }

}
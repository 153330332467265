/* Add these styles to your CSS */
.resend-code-link {
    color: blue;
    /* or your desired hyperlink color */
    text-decoration: underline;
    cursor: pointer;
    padding: 16px;
}

.error-display-pwd {
    color: red;
    float: left;

}

.valid-display-pwd {
    color: green;
    float: left;

}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__inputWrapper.ABB_CommonUX_Input__hasFixedSize {
    width: 300px;
}

/* .forgot-password-link:hover {
    text-decoration: none;
    color: red;
} */
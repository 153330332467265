.pane-header {
    display: flex;
    padding-left: 38px;
    font-weight: 700;

}

.chk-overflow {
    width: 190px;
    text-overflow: ellipsis;
}

.filters-header {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: baseline;

}

.left-pane-container .ABB_CommonUX_AppContainer__root.filter-container-main {
    /* width: 100% !important; */
    background: none !important;
    transition: none !important;
    /* width: auto !important; */

}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__smallSize>label {
    float: left !important;
}

.ABB_CommonUX_AppContainer__root.filter-container-main {
    width: 100% !important;
    background: none !important;
    max-width: 300px !important;
}

.ABB_CommonUX_AppLeftPane__root {
    transition: none !important;
}

.left-pane-container .ABB_CommonUX_Collapsible__containerRoot {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    white-space: nowrap;
}

.filter-app-left-pane {
    background: #FFF !important;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    width: 100vw;
    left: 0;
    top: -4px;
    padding-bottom: 150px;
    z-index: 3;
    overflow-x: hidden;
}

.ABB_CommonUX_Checkbox__root.ABB_CommonUX_Checkbox__checked>label:before {
    border: 2px solid black;
    background: rgb(51, 102, 255);
}

.ABB_CommonUX_Checkbox__root>label:before {
    border: 2px solid black;

}

.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__expanded {
    min-width: 40px;
    width: 100%;
    max-width: 300px;
}

.filter-sidebar-navi {
    width: 45px;
    float: left;
    background: none !important;
}

.ABB_CommonUX_Collapsible__root>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__title {
    flex: none;
}

.filter-app-left-pane .ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header {
    background: none !important;
}

.filter-app-left-pane .ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__light:after,
.ABB_CommonUX_AppLeftPane__root.ABB_CommonUX_AppLeftPane__light>.ABB_CommonUX_AppLeftPane__collapserIcon {
    background: none !important;
}

.filter-container-main .ABB_CommonUX_AppLeftPane__root>.ABB_CommonUX_AppLeftPane__title {
    margin-top: 15px;
}

.filter-app-left-pane .ABB_CommonUX_Collapsible__root__contentInner {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    left: 50px;
}

.ABB_CommonUX_Checkbox__root .ABB_CommonUX_Checkbox__light .ABB_CommonUX_Checkbox__smallSize {
    margin-left: 40px !important
}

.ABB_CommonUX_Collapsible__root__contentOuter {
    height: auto;
    transition: 100ms linear 0s;
    overflow: hidden;
    padding-left: 24px;

}
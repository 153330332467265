.last-updated {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.substation-waiting {

  font-size: 14px;
  font-weight: 400;
  color: #666;
  display: flex;
  justify-content: left;
}